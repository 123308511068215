.announcement {
  text-align: center;
  z-index: 1200;

  p {
    font-size: .9rem;
  }

  & :last-child {
    margin-bottom: 0 !important;
  }
}
